<template>
  <div class="copyright">Copyright © {{ year }} {{ ownerName }}</div>
</template>

<script>
export default {
  name: 'Copyright',
  props: ['ownerName'],
  computed: {
    year: function () {
      return '2021'
    }
  }
}
</script>
