<template>
  <div class="panel"><slot></slot></div>
</template>

<script>
export default {
  name: 'Panel'
}
</script>

<style lang="scss" scoped>
@import '../sass/colors';
@import '../sass/typography';

.panel {
  background-color: lighten($color-secondary-1, 15%);
  border: 2px solid $color-tertiary-2;
  border-radius: 5px;
  padding: 20px;
  margin: 0px 20px;

  h2 {
    @extend .text-color-tertiary-3;
    @extend .section-header-font;
    text-align: center;

    font-size: 4em;
  }

  .description {
    @extend .text-color-tertiary-4;
    @extend .default-serif-font;
    margin-top: 10px;

    font-size: 1.5em;
  }
}
</style>
