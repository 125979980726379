<template>
  <div
    :class="classNames"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
      intersection: {
        threshold: 0.3
      }
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Section',
  props: ['color', 'name'],
  computed: {
    classNames: function () {
      return 'section ' + this.color
    }
  },
  methods: {
    visibilityChanged: function (isVisible) {
      if (isVisible) {
        this.$gtag.event('view_section_' + this.name)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../sass/colors';

.section {
  padding: 50px 0px;
  width: 100%;
}
.primary {
  background-image: linear-gradient(
    lighten($color-primary-1, 5%),
    lighten($color-primary-1, 15%)
  );
}
.secondary {
  background-image: linear-gradient(
    lighten($color-secondary-1, 5%),
    lighten($color-secondary-1, 15%)
  );
}
.tertiary {
  background-image: linear-gradient(
    lighten($color-tertiary-1, 5%),
    lighten($color-tertiary-1, 15%)
  );
}
</style>
