<template>
  <div class="social-icons">
    <SocialIcon
      v-for="icon in icons"
      :key="icon.id"
      :icon="icon"
      color="text-color-white"
    ></SocialIcon>
  </div>
</template>

<script>
import SocialIcon from './SocialIcon'

export default {
  name: 'SocialIcons',
  components: {
    SocialIcon
  },
  data: function () {
    return {
      icons: [
        {
          id: 1,
          name: 'facebook',
          url: 'https://fb.me/lanawetzelbooks'
        },
        {
          id: 2,
          name: 'instagram',
          url: 'https://www.instagram.com/wetzellana/'
        },
        {
          id: 3,
          name: 'youtube',
          url: 'https://www.youtube.com/channel/UCDCVdDXOHk710zcelBGTKbA'
        },
        {
          id: 4,
          name: 'inbox',
          url:
            'https://us2.list-manage.com/contact-form?u=36396decd00e165cd15b2ab36&form_id=1d523b0e7b5ce1cf2b057d4fc3beea58'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
@import '../sass/colors';

.social-icons {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .social-icon {
    font-size: 32px;
    padding: 8px;
    a {
      @extend .text-color-white;
    }
    a:hover {
      @extend .text-color-primary-1;
    }
    a:active {
      @extend .text-color-primary-2;
    }
  }
}
</style>
