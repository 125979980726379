<template>
  <div class="social-icon">
    <a :href="icon.url" target="_blank" v-on:click="onClick"
      ><span :class="classlist"></span
    ></a>
  </div>
</template>

<script>
export default {
  name: 'SocialIcon',
  props: ['icon', 'color'],
  computed: {
    classlist: function () {
      return 'icon-' + this.icon.name
    }
  },
  methods: {
    onClick: function () {
      this.$gtag.event('click_social_icon_' + this.icon.name)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../sass/icons';

.social-icon {
  a {
    opacity: 0.8;
    text-decoration: none;
    transition: text-shadow 0.5s ease, opacity 0.5s ease, color 0.5s ease;
  }
  a:hover {
    text-shadow: 2px 2px rgba(0, 0, 0, 0.25);
  }
  a:active {
    opacity: 1;
  }
}
</style>
