<template>
  <div class="bio">
    <div class="headshot">
      <img src="../assets/images/headshot.jpg" alt="Lana Wetzel" />
    </div>
    <Panel>
      <div class="description">{{ bioText }}</div>
      <div class="quote">&quot;{{ quoteText }}&quot;</div>
      <div class="signature">{{ signature }}</div>
    </Panel>
  </div>
</template>

<script>
import Panel from './Panel'

export default {
  name: 'Bio',
  components: {
    Panel
  },
  data: function () {
    return {
      bioText:
        'As a writer of inspirational fiction and singer/songwriter,' +
        ' Lana offers a unique gift in service to her Lord and Savior, Jesus Christ.' +
        ' Characterized by her experiences growing up in rural Northeast Georgia' +
        ' and now as a wife and homeschool mother of four, her works reflect an honesty' +
        ' and a gentle natural beauty that is often missed and sorely needed in a' +
        ' culture saturated with transient entertainment and consumerism. Whether' +
        ' making meals, lesson plans, story arcs, or song lyrics, Lana is listening' +
        ' for the still quiet Voice to draw her nearer to where even messiness and failure' +
        ' can become a redeemed work of art.',
      quoteText: 'Thank you for all your love and support!',
      signature: 'Lana Wetzel'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../sass/media';
@import '../sass/typography';
@import '../sass/colors';

.bio {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .headshot {
    img {
      border-radius: 5px;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
      transition: width 0.5s ease;

      @media (max-width: $media-md) {
        width: 300px;
      }

      @media (min-width: $media-md + 1) {
        width: 400px;
      }
    }
  }

  .panel {
    background-color: transparent;
    border-color: $color-gray-2;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 15px;
    transition: width 0.5s ease;

    @media (max-width: $media-sm) {
      width: 400px;
    }

    @media (min-width: $media-sm + 1) {
      width: 500px;
    }

    .description {
      @extend .default-serif-font;
      @extend .text-color-gray-4;
      width: 100%;
      font-size: 1.2em;
    }
    .quote {
      @extend .default-serif-font;
      @extend .text-color-gray-4;
      width: 100%;
      margin: 20px 0;
      font-size: 1.2em;
      font-style: italic;
    }
    .signature {
      @extend .section-header-font;
      @extend .text-color-gray-4;
      text-align: center;
      font-size: 3em;
      margin-top: 0.25em;
    }
  }
}
</style>
