<template>
  <div class="newsletter">
    <div class="newsletter-logo">
      <img src="../assets/images/newsletter-logo.svg" />
    </div>
    <Panel class="newsletter-copy">
      <h2>{{ headerText }}</h2>
      <div class="description"><span v-html="descriptionText"></span></div>
      <MailchimpCondensed />
    </Panel>
  </div>
</template>

<script>
import MailchimpCondensed from './MailchimpCondensed'
import Panel from './Panel'

export default {
  name: 'Newsletter',
  components: {
    Panel,
    MailchimpCondensed
  },
  data: function () {
    return {
      headerText: 'Subscribe!',
      descriptionText:
        'Join the mailing list to get special offers and updates on new releases. <strong>If you subscribe today, you get the first two chapters of <em>Presence: The Story of Adel</em> for free!</strong>',
      buttonUrl: 'http://eepurl.com/henrp1'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../sass/colors';
@import '../sass/media';
@import '../sass/buttons';

.newsletter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .newsletter-logo {
    img {
      transition: height 0.5s ease;
      @media (max-width: $media-md) {
        height: 200px;
      }
      @media (min-width: $media-md +1) {
        height: 250px;
      }
    }
  }

  .newsletter-copy {
    width: 400px;
    .submit-button {
      margin-top: 30px;
      text-align: center;
    }
  }
}
</style>
