<template>
  <div id="main">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'Main'
}
</script>

<style lang="scss" scoped>
@import '../sass/colors';

#main {
  @extend .bg-color-gray-1;
  @extend .text-color-black;

  flex: 2;
}
</style>
