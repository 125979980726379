<template>
  <div>
    <div id="mc_embed_signup">
      <form
        action="https://lanawetzel.us2.list-manage.com/subscribe/post?u=36396decd00e165cd15b2ab36&amp;id=625c203681"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        class="validate"
        target="_blank"
        v-on:submit="onSubmit"
        novalidate
      >
        <div id="mc_embed_signup_scroll">
          <input
            type="email"
            value=""
            name="EMAIL"
            class="email"
            id="mce-EMAIL"
            placeholder="email address"
            required
          />
          <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
          <div style="position: absolute; left: -5000px" aria-hidden="true">
            <input
              type="text"
              name="b_36396decd00e165cd15b2ab36_625c203681"
              tabindex="-1"
              value=""
            />
          </div>
          <div>
            <input
              type="submit"
              value="Subscribe"
              name="subscribe"
              id="mc-embedded-subscribe"
              class="button-primary"
            />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MailchimpCondensed',
  components: {},
  data: function () {
    return {}
  },
  methods: {
    onSubmit: function () {
      this.$gtag.event('click_subscribe')
    }
  }
}
</script>

<style lang="scss">
@import '../sass/colors';
@import '../sass/buttons';
@import '../sass/typography';

#mc_embed_signup {
  #mc_embed_signup_scroll {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .email {
    @extend .default-serif-font;
    font-size: 1.5em;
    width: 100%;
    padding: 12px 20px;
    margin: 20px 0px;
    color: $color-primary-3;
    box-sizing: border-box;
    border-color: $color-tertiary-2;
    border-style: solid;
    border-radius: 5px;
  }
}
</style>
