<template>
  <div class="home"><Colors></Colors></div>
</template>

<script>
import Colors from '../Colors'
export default {
  name: 'ColorPallete',
  components: {
    Colors
  }
}
</script>

<style lang="scss" scoped></style>
