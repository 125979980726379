<template>
  <header>
    <Title></Title>
    <SocialIcons></SocialIcons>
  </header>
</template>

<script>
import Title from './Title'
import SocialIcons from './SocialIcons'

export default {
  name: 'Header',
  components: {
    Title,
    SocialIcons
  }
}
</script>

<style lang="scss" scoped>
@import '../sass/colors';
@import '../sass/media';

header {
  @extend .bg-color-secondary-1;
  $min-height: 500px;
  flex: 1;
  background-repeat: no-repeat;
  background-position: center;
  background-position: middle center;
  display: flex;
  flex-direction: column;
  align-content: middle;
  justify-content: space-evenly;
  height: 100%;
  min-height: $min-height;
  background-size: cover;

  @media (max-width: $media-md) {
    background-image: url('../assets/images/header-small.jpg'),
      url('../assets/images/header-optimized.jpg');
  }
  @media (min-width: $media-md +1) {
    background-image: url('../assets/images/header.jpg'),
      url('../assets/images/header-optimized.jpg');
  }

  .title {
    min-height: 420px;
  }
}
</style>
