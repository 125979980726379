<template>
  <div class="reviews">
    <h2 v-html="headerText"></h2>
    <div class="review-list">
      <div class="review" v-for="review in reviews" :key="review.id">
        <div class="review-text">&quot;{{ review.text }}&quot;</div>
        <div class="reviewer">-{{ review.reviewer }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Reviews',
  props: ['reviews'],
  data: function () {
    return {
      headerText: 'What others are saying...'
    }
  },
  computed: {
    currentReview: function () {
      return this.reviews[0]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../sass/colors';
@import '../sass/typography';
@import '../sass/media';

.reviews {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    @extend .section-header-font;
    @extend .text-color-primary-3;
    font-size: 2.5em;
    margin: 10px;
    text-align: center;
  }
  .review-list {
    display: flex;
    justify-content: center;
    align-items: top;
    flex-direction: row;
    flex-wrap: wrap;

    .review {
      margin: 20px;
      @media (min-width: $media-md) {
        width: 400px;
      }

      .review-text {
        @extend .default-serif-font;
        @extend .text-color-gray-4;
        font-size: 1.2em;
      }
      .reviewer {
        @extend .section-header-font;
        @extend .text-color-gray-3;
        font-size: 1.5em;
        margin-top: 10px;
        margin-left: 3em;
      }
    }
  }
}
</style>
