<template>
  <div class="home">
    <Section color="primary" name="subscription">
      <Newsletter></Newsletter>
    </Section>
    <Section color="tertiary" name="presence_book">
      <BookRelease></BookRelease>
    </Section>
    <Section color="primary" name="bio">
      <Bio></Bio>
    </Section>
  </div>
</template>

<script>
import Section from '../Section'
import BookRelease from '../BookRelease'
import Newsletter from '../Newsletter'
import Bio from '../Bio'

export default {
  name: 'Home',
  components: {
    Section,
    BookRelease,
    Bio,
    Newsletter
  }
}
</script>

<style lang="scss">
.home {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
