<template>
  <div class="colors">
    <div class="color bg-color-primary-1"></div>
    <div class="color bg-color-primary-2"></div>
    <div class="color bg-color-primary-3"></div>
    <div class="color bg-color-primary-4"></div>
    <div class="color bg-color-primary-5"></div>
    <div class="color bg-color-secondary-1"></div>
    <div class="color bg-color-secondary-2"></div>
    <div class="color bg-color-secondary-3"></div>
    <div class="color bg-color-secondary-4"></div>
    <div class="color bg-color-secondary-5"></div>
    <div class="color bg-color-tertiary-1"></div>
    <div class="color bg-color-tertiary-2"></div>
    <div class="color bg-color-tertiary-3"></div>
    <div class="color bg-color-tertiary-4"></div>
    <div class="color bg-color-tertiary-5"></div>
    <div class="color bg-color-gray-1"></div>
    <div class="color bg-color-gray-2"></div>
    <div class="color bg-color-gray-3"></div>
    <div class="color bg-color-gray-4"></div>
    <div class="color bg-color-gray-5"></div>
    <div class="color bg-color-white"></div>
    <div class="color bg-color-black"></div>
  </div>
</template>

<script>
export default {
  name: 'Colors'
}
</script>

<style lang="scss" scoped>
@import '../sass/colors';

.colors {
  height: 700px;
  display: grid;
  justify-content: space-evenly;
  align-content: space-evenly;
  grid-template-columns: auto auto auto auto auto;

  .color {
    height: 100px;
    width: 100px;
    border: 1px solid $color-black;
  }
}
</style>
