<template>
  <div class="book-release">
    <div class="book-cover">
      <img
        title="Presence Book"
        src="../assets/images/presence-ebook-cover.png"
      />
    </div>
    <Panel>
      <h2>{{ headerText }}</h2>
      <div class="description"><span v-html="descriptionText"></span></div>
      <div class="submit-button">
        <a
          target="_blank"
          :href="buttonUrl"
          class="button-tertiary"
          v-on:click="onClickPurchase"
          >{{ buttonText }}</a
        >
      </div>
    </Panel>
    <Reviews :reviews="bookReviews"></Reviews>
  </div>
</template>

<script>
import Panel from './Panel'
import Reviews from './Reviews'

export default {
  name: 'Release',
  components: {
    Panel,
    Reviews
  },
  data: function () {
    return {
      headerText: 'Just Released!',
      descriptionText:
        "<strong>Lana's debut novel, <em>Presence: The Story of Adel</em>, is available online for purchase!</strong><br><br>" +
        "Adel, a single mom from San Francisco, gets the chance to start a new life when she becomes the owner of her grandmother's farm, Sweet Valley." +
        ' Faced with the hardships of single-parenting and solitude, she learns the necessity of faith, family, and community as she struggles to get the farm operational' +
        ' and establish a new home for her son, Noah.' +
        ' Join her as she finds love and family and discovers what it truly means to be <em>present.</em>' +
        '<br><br>We think you will enjoy the small-town feel and the gentle pace in the Blue Ridge Mountain town of Cherry Hill' +
        ' as you get to know Adel and the community she comes to love.',
      buttonText: 'Purchase',
      buttonUrl: 'https://www.amazon.com/dp/0578906708',
      bookReviews: [
        {
          id: 1,
          text:
            'Presence is an absolute pleasure to read. Heartwarming and touching,' +
            ' readers will fall in love with the characters and setting of this story.' +
            ' In between tears and laughter is the reminder of the transforming power of the presence of God in our lives and how He masterfully uses community to bless and grow His children.',
          reviewer: 'Lori B'
        },
        {
          id: 3,
          text:
            'This is a lovely, moving story with well written well thought-out characters. It is full of real life hard things and the beauty that God can bring from them.' +
            '...This book may appeal to many but I must say it spoke to me especially as a mom...',
          reviewer: 'Samantha M'
        },
        {
          id: 2,
          text:
            "'Presence' is an inspirational read from new author, Lana Wetzel." +
            " ...Read this powerful story of one woman's strength" +
            " and courage as she embarks on a wonderful journey with God's PRESENCE.",
          reviewer: 'Faith H'
        },
        {
          id: 4,
          text:
            "I really enjoyed this nostalgic, gently-paced story of healing and personal growth... with humor, inspiration, a bit of drama, and some light romantic tension in just the right places. It's the type of story you soak in as it unfolds leisurely...",
          reviewer: 'Jessica I'
        },
        {
          id: 5,
          text:
            'The Story of Adel was so fun to read and it was hard to put down...This book would be a great companion to curl up with on a cozy afternoon!',
          reviewer: 'Jenn S'
        }
      ]
    }
  },
  methods: {
    onClickPurchase: function () {
      this.$gtag.event('click_purchase_presence')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../sass/colors';
@import '../sass/buttons';
@import '../sass/media';

.book-release {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .book-cover {
    text-align: center;
    img {
      border-radius: 5px;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
      transition: width 0.5s ease;

      @media (max-width: $media-md) {
        width: 300px;
      }

      @media (min-width: $media-md + 1) {
        width: 400px;
      }
    }
  }
  .panel {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 15px;
    transition: width 0.5s ease;

    @media (max-width: $media-sm) {
      width: 400px;
    }

    @media (min-width: $media-sm + 1) {
      width: 500px;
    }

    .description {
      font-size: 1.1em;
    }

    .submit-button {
      margin-top: 30px;
      text-align: center;
    }
  }

  .reviews {
    margin-top: 20px;
  }
}
</style>
