import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/pages/Home'
import ColorPalette from '../components/pages/ColorPalette'
import { setRouteHtmlAttributes } from '../lib/router-utils'

Vue.use(VueRouter)

let homeTitle = 'Lana Wetzel, Author of Inspirational Fiction'
let homeDescription =
  'Lana Wetzel is a Georgia based author of Christian Inspirational Fiction.'
let domain = process.env.VUE_APP_DOMAIN
let homeUrl = 'https://' + domain

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: homeTitle,
      metaTags: [
        {
          name: 'description',
          content: homeDescription
        },
        {
          property: 'og:url',
          content: homeUrl
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: 'og:title',
          content: homeTitle
        },
        {
          property: 'og:description',
          content: homeDescription
        },
        {
          property: 'og:image',
          content: homeUrl + require('@/assets/images/og-image.jpg')
        },
        {
          property: 'twitter:card',
          content: 'summary_large_image'
        },
        {
          property: 'twitter:domain',
          content: 'lanawetzel.com'
        },
        {
          property: 'twitter:url',
          content: homeUrl
        },
        {
          property: 'twitter:title',
          content: homeTitle
        },
        {
          property: 'twitter:description',
          content: homeDescription
        },
        {
          property: 'twitter:image',
          content: homeUrl + require('@/assets/images/twitter-image.jpg')
        }
      ]
    }
  },
  {
    path: '/color-palette',
    name: 'ColorPalette',
    component: ColorPalette,
    meta: {
      title: 'Color Palette - Lana Wetzel',
      metaTags: [
        {
          name: 'description',
          content:
            'Lana Wetzel is a Georgia based author of Inspirational Fiction.'
        },
        {
          property: 'og:description',
          content:
            'Lana Wetzel is a Georgia based author of Inspirational Fiction.'
        }
      ]
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(setRouteHtmlAttributes)

export default router
