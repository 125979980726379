import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueGtag from 'vue-gtag'
import VueObserveVisibility from 'vue-observe-visibility'

Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_GTAG_ID
  }
})

Vue.use(VueObserveVisibility)

new Vue({
  render: (h) => h(App),
  router,
  mounted: () => document.dispatchEvent(new Event('x-app-rendered'))
}).$mount('#app')
