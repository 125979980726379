<template>
  <div class="title">
    <h1>{{ title }}</h1>
    <img src="../assets/images/section-break-white.svg" class="section-break" />
    <span id="subtitle">Author of Christian Inspirational Fiction</span>
  </div>
</template>

<script>
export default {
  name: 'Title',
  data: () => {
    return {
      title: 'Lana Wetzel'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../sass/colors';
@import '../sass/media';
@import '../sass/typography';

h1 {
  @extend .header-font;
  @extend .text-color-white;
  // @extend .default-text-shadow;

  font-size: 5em;
  text-align: center;
}

#subtitle {
  @extend .subheader-font;
  @extend .text-color-primary-1;
  // @extend .default-text-shadow;

  font-size: 1.75em;
  text-align: center;
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.section-break {
  width: 300px;
  opacity: 0.8;
}
</style>
