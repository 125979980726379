<template>
  <footer>
    <SocialIcons></SocialIcons>
    <Copyright ownerName="Lana Wetzel"></Copyright>
  </footer>
</template>

<script>
import SocialIcons from './SocialIcons'
import Copyright from './Copyright'

export default {
  name: 'Footer',
  components: {
    SocialIcons,
    Copyright
  }
}
</script>

<style lang="scss" scoped>
@import '../sass/colors';
@import '../sass/media';
@import '../sass/typography';

footer {
  @extend .bg-color-secondary-1;

  flex: 1;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-position: center;
  background-position: middle center;
  background-size: cover;
  min-height: 180px;
  background-image: url('../assets/images/footer-small.jpg'),
    url('../assets/images/footer-optimized.jpg');

  @media (max-width: $media-md) {
    background-image: url('../assets/images/footer-small.jpg'),
      url('../assets/images/footer-optimized.jpg');
  }
  @media (min-width: $media-md+1) {
    background-image: url('../assets/images/footer.jpg'),
      url('../assets/images/footer-optimized.jpg');
  }

  .social-icons {
    padding-top: 12px;
    flex-grow: 1.5;
  }

  .copyright {
    @extend .default-serif-font;
    @extend .text-color-white;

    align-self: flex-end;
    padding-right: 20px;
    padding-bottom: 10px;
    font-size: 1.2em;
    font-style: italic;
  }
}
</style>
